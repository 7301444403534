<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Market Watch</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
       
        <div class="search-wrapper market-watch-search">
          <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            clearable
            @clear="handleSearch()" 
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button type="primary" @click="handleCreate()">Add</el-button>
          <el-button type="primary" @click="stockImport()" v-if = "has_import">Import Stock</el-button>
        </div>
      </el-col>
    </el-row>

    <div>
      <el-tabs class="marketwatch_tab" type="card" @tab-click="handleTabClick" v-model="currentTab">
        <el-tab-pane label="NSE" name="nse">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              ref="nse_table"
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataNSE"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionViewVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionEditVisibility="false"
              :tableLockActionVisibility="tableLockActionVisibility"
              :tableLoadingStatus.sync="loading"
              :tablePagination="tablePagination"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
              @lock="handleLock($event)"
              @removeLock="removeLock($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="MCX" name="mcx">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataMCX"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionViewVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionEditVisibility="false"
              :tableLockActionVisibility="tableLockActionVisibility"
              :tableLoadingStatus.sync="loading"
              :tablePagination="tablePagination"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
              @lock="handleLock($event)"
              @removeLock="removeLock($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="OPT" name="opt">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataOPT"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionViewVisibility="false"
              :tableActionEditVisibility="false"
              :tableLockActionVisibility="tableLockActionVisibility"
              :tablePagination="tablePagination"
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
              @lock="handleLock($event)"
              @removeLock="removeLock($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="CRYPTO" name="crypto" v-if="tableCryptoVisibility">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataCRYPTO"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionViewVisibility="false"
              :tableActionEditVisibility="false"
              :tableLockActionVisibility="tableLockActionVisibility"
              :tablePagination="tablePagination"
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
              @lock="handleLock($event)"
              @removeLock="removeLock($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="FOREX" name="forex" v-if="tableForexVisibility">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataFOREX"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionViewVisibility="false"
              :tableActionEditVisibility="false"
              :tableLockActionVisibility="tableLockActionVisibility"
              :tablePagination="tablePagination"
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
              @lock="handleLock($event)"
              @removeLock="removeLock($event)"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <br />
    <br />

    <br />

    <AddEditDialog
        :dialog-visible.sync="dialogVisible"
        :dialog-type="dialogType"
        :form-data="addData"
        :form-errors.sync="formErrors"
        @getChildFormData="handleChildFormData($event)"
        @childClose="handleChildClose()"
        />

      <EditDialog
        :dialog-visible.sync="dialogVisibleEdit"
        :dialog-type="dialogTypeEdit"
        :form-data="formData"
        :form-errors.sync="formErrors"
        @getChildFormData="handleChildFormDataEdit($event)"
        @childClose="handleChildCloseEditScript()"
      />
  </div>
</template>

<script>
import CustomeMarketWatchTable from "@/components/Table/CustomeMarketWatchTable.vue";
import { fetchList,addStock, destroy, edit, update,lock , importStock} from "@/api/market-watch";
import AddEditDialog from "@/views/super-admin/market-watch/components/AddEditDialog.vue";
//import getRoleSlug from "@/store/app.js";
import { mapGetters } from "vuex";
//import { _ } from "vue-underscore";
import EditDialog from "@/views/super-admin/market-watch/components/EditDialog.vue";



export default {
  name: "ListClosePositions",
  components: {
    CustomeMarketWatchTable,
    AddEditDialog,
    EditDialog
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleEdit: false,
      loading: false,
      tableLockActionVisibility: true,
      tableCryptoVisibility: true,
      tableForexVisibility: true,
      config: [
        {
          label: "Script",
          prop: "name",
          width: "",
          className: "redFont",
        },
        {
          label: "Ask",
          prop: "ask",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Bid",
          prop: "bid",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Ltp",
          prop: "ltp",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Change",
          prop: "ch",
          width: "110",
          className: "blueFont",
        },
        {
          label: "High",
          prop: "high",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Low",
          prop: "low",
          width: "110",
          className: "blueFont",
        },
      ],
      addData: {
        exchange: "NSE"
      },
      listDataNSE: {
        data: {
          item: [],
        },
      },
      listDataOPT: {
        data: {
          item: [],
        },
      },
      listDataMCX: {
        data: {
          item: [],
        },
      },
      listDataCRYPTO: {
        data: {
          item: [],
        },
      },
      listDataFOREX: {
        data: {
          item: [],
        },
      },
      tablePagination: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },

      options: [
        {
          value: "market",
          label: "Market",
        },
        {
          value: "order",
          label: "Order",
        },
      ],
      listDataDropdownAll: {},
      formData: {
        by_market: null,
      },
      formErrors: [],
      dialogType: "",
      dialogTypeEdit: "edit",
      search: "",
      currentTab: "nse",
      type:"nse",
      has_import: true,
      isConnected: false,
      socketMessage: ''
    };
  },
  sockets: {
    connect: function () {
      console.log('connected live data')
    },
    updateStockNSE: function (data) {
      if(data && this.type == 'nse'){
        this.updateStockData('NSE', JSON.parse(data));
      }
    },

    updateStockMCX: function (data) {
      if(data && this.type == 'mcx'){
        this.updateStockData('MCX', JSON.parse(data));
      }
    },
    updateStockOPT: function (data) {
      if(data && this.type == 'opt'){
        this.updateStockData('OPT', JSON.parse(data));
      }
    },
    updateStockCRYPTO: function (data) {
      if(data && this.type == 'crypto'){
        this.updateStockData('CRYPTO', JSON.parse(data));
      }
    },
    updateStockFOREX: function (data) {
      if(data && this.type == 'forex'){
        this.updateStockData('FOREX', JSON.parse(data));
      }
    }
},
  computed: {

    ...mapGetters(["getRoleSlug"]),
  },
  
  created() {
    this.getList();
  },

  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSearch() {
       this.listQuery.page = 1;
        this.getList();
    },
    handleTabClick(tab) {
          if (tab.index == 0) {
            this.type = 'nse'
          } else if (tab.index == 1) {
            this.type = 'mcx'
          } else if (tab.index == 2) {
            this.type = 'opt'
          }else if (tab.index == 3) {
            this.type = 'crypto'
          }else if(tab.index == 4){
            this.type = 'forex'
          }
          this.getList();
      },
    // //////////////////
    // HANDLE SEARCH
    // //////////////////
    // handleSearch() {
    //   if(this.type == 'nse') {
    //         this.listDataNSE.data.item = this.listDataTemp;
    //         let s = this.search;
    //         s = s.toLowerCase();
    //         this.listDataNSE.data.item = _.filter(this.listDataNSE.data.item, function (d) {
    //           return d["name"] && d["name"].toLowerCase().includes(s);
    //         });
    //   } else if(this.type == 'mcx') {
    //         this.listDataTempMcx.data.item = this.listDataTempMcx;
    //         let s = this.search;
    //         s = s.toLowerCase();
    //         this.listDataTempMcx.data.item = _.filter(this.listDataTempMcx.data.item, function (d) {
    //           return d["name"] && d["name"].toLowerCase().includes(s);
    //         });
    //   } else if(this.type == 'opt') {
    //         this.listDataOPT.data.item = this.listDataTempOPT;
    //         let s = this.search;
    //         s = s.toLowerCase();
    //         this.listDataOPT.data.item = _.filter(this.listDataOPT.data.item, function (d) {
    //           return d["name"] && d["name"].toLowerCase().includes(s);
    //         });
    //   } else if(this.type == 'crypto') {
    //         this.listDataCRYPTO.data.item = this.listDataTempCRYPTO;
    //         let s = this.search;
    //         s = s.toLowerCase();
    //         this.listDataCRYPTO.data.item = _.filter(this.listDataCRYPTO.data.item, function (d) {
    //           return d["name"] && d["name"].toLowerCase().includes(s);
    //         });
    //   }else {
    //     console.log('Oops....')
    //   }

    // },
    getList() {
      this.loading = true;
      this.listQuery.exchange = this.type ?? 'nse';
      fetchList(this.listQuery).then((response) => {
       this.listDataTemp =  this.listDataNSE.data.item = response.data.data.nse;
       this.listDataTempOPT = this.listDataOPT.data.item = response.data.data.opt;
       this.listDataTempMcx = this.listDataMCX.data.item = response.data.data.mcx;
       this.listDataTempCRYPTO = this.listDataCRYPTO.data.item = response.data.data.crypto;
       this.listDataTempFOREX = this.listDataFOREX.data.item = response.data.data.forex;
       this.listDataDropdownAll = response.data.data.all;
       this.tablePagination = response.data.data.meta;
       this.loading = false;
      });
    },

    handleCreate(){
      this.dialogType = 'create';
      this.dialogVisible = true;
      this.addData = {
        exchange: 'NSE'
      }
    },
     handleChildFormData(data) {
      if (data.dialog_type === "create") {
        this.handleStore(data);
      }
    },
    handleStore(data){
        this.loading = true;
        this.dialogVisible = false;
        addStock(data).then((response) => {
          if(response.data.success === true){
            this.flashSuccess(response.data.message);
            //this.handleChildClose();
            this.getList();
          }else{
            this.flashError(response.data.message);
            //this.handleChildClose();
            this.getList();
          }

        });
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleChildCloseEditScript() {
      this.dialogVisibleEdit = false;
    },
    // //////////////////
    // EDIT
    // //////////////////
    handleEdit(stock_id) {
      this.fetchData(stock_id);
    },
    fetchData(stock_id) {
      this.loading = true;
      edit(stock_id)
        .then((response) => {
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.loading = false;
            this.dialogTypeEdit = "edit";
            this.dialogVisibleEdit = true;
          } else {
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    handleChildFormDataEdit(data) {
      this.loading = true;
      update(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisibleEdit = false;
            this.loading = false;
            this.flashSuccess(response.data.message);
            this.getList();
          } else {
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },


    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        destroy(data.stock_id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.loading = false;
          }
        });
      });
    },

    updateStockData(type, data){
      let index;
      switch (type) {
        case 'NSE':
          index = this.getStockDataIndex(this.listDataNSE.data.item, data);
          if(index != -1){
            //this.$set(this.listDataNSE.data.item, index, data);
            this.$set(this.listDataNSE.data.item, index, { ...this.listDataNSE.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;

        case 'MCX':
          index = this.getStockDataIndex(this.listDataMCX.data.item, data);
          if(index != -1){
            //this.$set(this.listDataMCX.data.item, index, data);
            this.$set(this.listDataMCX.data.item, index, { ...this.listDataMCX.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
            
          }
          break;

        case 'OPT':
          index = this.getStockDataIndex(this.listDataOPT.data.item, data);
          if(index != -1){
            //this.$set(this.listDataOPT.data.item, index, data);
            this.$set(this.listDataOPT.data.item, index, { ...this.listDataOPT.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;
        case 'CRYPTO':
          index = this.getStockDataIndex(this.listDataCRYPTO.data.item, data);
          if(index != -1){
            //this.$set(this.listDataCRYPTO.data.item, index, data);
            this.$set(this.listDataCRYPTO.data.item, index, { ...this.listDataCRYPTO.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;
        case 'FOREX':
          index = this.getStockDataIndex(this.listDataFOREX.data.item, data);
          if(index != -1){
            this.$set(this.listDataFOREX.data.item, index, { ...this.listDataFOREX.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;
        default:
          break;
      }
    },

    getStockDataIndex(arr, data){
      return arr.findIndex(x => x.stock_id === data.stock_id);
    },

    
    removeLock(data) {
      console.log("sdfsdfdsf    "  ,data);
      this.$confirm(
        "Would you like to remove from freeze list?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let pdata = {
          stock_id:data.stock_id,
          status: false
        }
        this.loading = true;
        lock(pdata).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },



    handleLock(data) {
      console.log(data.id);
      this.$confirm(
        "Would you like to add in your freeze list?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        let pdata = {
          stock_id:data.stock_id,
          status: true
        }
        lock(pdata).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },

    stockImport() {
      this.$confirm(
        "Would you like to import new stock?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        let query = {}
        importStock(query).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },



  },
};
</script>
