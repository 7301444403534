<template>
  <div
    class="menu_wrapper_container box-shadow trading-container"
  >
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-loading="loading"
    >
      <el-row>
        <el-col :span="24">
          <el-form
            :model="formData"
            :rules="formRules"
            ref="refForm"
            label-position="top"
            :status-icon="true"
          >
          <div  class="table-wrapper-market-watch">
          <el-row>
          <el-col :span="16">
            <el-radio-group v-model="formData.exchange" @change="radioFunction">
              <el-radio-button label="NSE"></el-radio-button>
              <el-radio-button label="MCX"></el-radio-button>
              <el-radio-button label="OPT"></el-radio-button>
              <el-radio-button label="CRYPTO"></el-radio-button>
              <el-radio-button label="FOREX"></el-radio-button>
            </el-radio-group>
         </el-col>
            <el-col :span="8">
              <el-input placeholder="Search"
            v-model="search"
            class="input-with-select"
            clearable @input="searchFunction()" ></el-input>
          </el-col>
        </el-row>
        <br><br>
          <el-row :gutter="10">
          
            <el-table
              :data="dropdown_data_set"
              stripe
              style="width: 100%"
              class="stock-add-table"
              >
              <el-table-column
                prop="TradeSymbol"
                label="Script"
                >
              </el-table-column>
              <el-table-column
                label="Action"
                width="100"
                >
                <template slot-scope="scope">
                  <el-button size="small" type="success" icon="el-icon-success" circle v-if="scope.row.has_stock === true"></el-button>
                  <el-button size="small" type="info" icon="el-icon-circle-plus" circle v-else @click="scriptItemDetailsFun(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
             <!-- // PAGINATION -->
          <el-pagination
            v-if="total"
            :total="total"
            :current-page.sync="page"
            :page-size.sync="limit"
            layout="prev, pager, next"
            @size-change="radioFunction()"
            @current-change="radioFunction()"
          />
       


            <!-- old code -->
            <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                    v-if="formData.exchange != 'OPT'"
                    :error="formErrors.name"
                    label="Name"
                    prop="name"
                    :label-width="formLabelWidth"
                    class="add_dialog_label"
                    
                  >
                    <br>
                      <el-select v-model="formData.name" filterable placeholder="Select"
                        clearable
                        @change="scriptItemDetailsFun"
                        >
                        <el-option
                          v-for="item in dropdown_data_set"
                          :key="item.TradeSymbol"
                          :label="item.Product + '_' + item.Expiry + '_' + item.OptionType + '_' + item.StrikePrice"
                          :value="item.TradeSymbol"

                          >
                        </el-option>
                      </el-select>
                </el-form-item>
              <el-form-item
                    v-show="formData.exchange == 'OPT'"
                    :error="formErrors.name"
                    label="OPT Name"
                    prop="name"
                    :label-width="formLabelWidth"
                    
                  >
                    <br>
                      <el-select v-model="formData.name" filterable placeholder="Select"
                        clearable
                        @change="scriptItemDetailsFun"
                        >
                        <el-option
                          v-for="item in opt_stocks_data_set"
                          :key="item.TradeSymbol"
                          :label="item.Product + '_' + item.Expiry + '_' + item.OptionType + '_' + item.StrikePrice"
                          :value="item.TradeSymbol"

                          >
                        </el-option>
                      </el-select>
                </el-form-item>
            </el-col> -->
           
          </el-row>
        </div>
          </el-form>
        </el-col>
      </el-row>
      <!-- {{dropdown_data_set}} -->
      <span slot="footer" class="dialog-footer">
       <el-button @click="handleCloseDialog()">Cancel</el-button>
        <!-- <el-button type="info" @click="sendChildFormData()">Save</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
//import { _ } from "vue-underscore";
import { fetchMcxNseList, addStock } from "@/api/market-watch";


export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {
          exchange: "NSE"
        };
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      nse_stocks: [],
      mcx_stocks: [],
      opt_stocks: [],
      mcx_stocks_data_set: [],
      opt_stocks_data_set: [],
      crypto_stocks_data_set: [],
      forex_stocks_data_set: [],
      nse_stocks_data_set: [],
      dropdown_data_set: [],

      page: 1,
      limit : 20,
      search: "",
      total: 0
    };
  },
  computed: {
    dialogTitle() {
      return this.dialogType === "create" ? "Add Stock" : "";
    },
  },
  created() {
    this.formRules.name = requiredRule("name");
    this.formRules.lots = requiredRule("lots");
    // this.getMCXList();
    this.getNSEList();
    this.getMCXList();
    this.getOPTList();
    this.getCRYPTOList();
    this.getFOREXList();
  },
  methods: {

    radioFunction() {

      if(this.formData.exchange == "NSE"){
        //this.getNSEList();
         this.dropdown_data_set = this.getTableList(this.nse_stocks_data_set);
      }else if(this.formData.exchange == "MCX"){
        //this.getMCXList()
         this.dropdown_data_set = this.getTableList(this.mcx_stocks_data_set);
      }else if(this.formData.exchange == "OPT"){
        //this.getOPTList()
        this.dropdown_data_set = this.getTableList(this.opt_stocks_data_set);
      }else if(this.formData.exchange == "CRYPTO"){
        this.dropdown_data_set = this.getTableList(this.crypto_stocks_data_set)
      }else if(this.formData.exchange == "FOREX"){
        this.dropdown_data_set = this.getTableList(this.forex_stocks_data_set)
      }
    },

    getTableList(items) {
      this.total =  items?.length;
      return items?.slice((this.page - 1) * this.limit, this.page  * this.limit);
    },

    searchFunction() {
      this.page = 1;
      console.log("DFDFDS  fffffffff")
      if(this.formData.exchange == "NSE"){
        let new_stock_arr = this.nse_stocks_data_set.filter((item) =>
          {
              return item.Identifier.includes(this.search.toUpperCase())  ;
          });
          this.dropdown_data_set = this.getTableList(new_stock_arr);
      }else if(this.formData.exchange == "MCX"){
        let new_stock_arr = this.mcx_stocks_data_set.filter((item) =>
          {
              return item.Identifier.includes(this.search.toUpperCase())  ;
          });
          this.dropdown_data_set = this.getTableList(new_stock_arr);
      }else if(this.formData.exchange == "OPT"){
        let new_stock_arr  = this.opt_stocks_data_set.filter((item) => 
          {
              return item.Identifier.includes(this.search.toUpperCase())  ;
          });
          this.dropdown_data_set = this.getTableList(new_stock_arr);
      }else if(this.formData.exchange == "CRYPTO"){
        let new_stock_arr  = this.crypto_stocks_data_set.filter((item) => 
          {
              return item.Identifier.includes(this.search.toUpperCase())  ;
          });
          this.dropdown_data_set = this.getTableList(new_stock_arr);
      }else if(this.formData.exchange == "FOREX"){
          let new_stock_arr  = this.forex_stocks_data_set.filter((item) => 
          {
              return item.Identifier.includes(this.search.toUpperCase())  ;
          });
          this.dropdown_data_set = this.getTableList(new_stock_arr);
      }

    },
    
    getMCXList() {
      let listQuery = {
        "type": "MCX" ,
      }
      this.loading = true;
      fetchMcxNseList(listQuery).then((response) => {
         this.mcx_stocks_data_set = response.data.data;
      });
      this.loading = false;
    },
    getOPTList() {
      let listQuery = {
        "type": "OPT" ,
      }
      this.loading = true;
      fetchMcxNseList(listQuery).then((response) => {
         this.opt_stocks_data_set = response.data.data;
      });
      this.loading = false;
    },

    getNSEList() {
      let listQuery = {
        "type": "NSE" ,
      }
      this.loading = true;
      fetchMcxNseList(listQuery).then((response) => {
        this.nse_stocks_data_set = response.data.data;
        this.dropdown_data_set = this.getTableList(this.nse_stocks_data_set)
      });
      this.loading = false;
    },
    getCRYPTOList() {
      let listQuery = {
        "type": "CRYPTO" ,
      }
      this.loading = true;
      fetchMcxNseList(listQuery).then((response) => {
        this.crypto_stocks_data_set = response.data.data;
        //this.dropdown_data_set = this.getTableList(this.crypto_stocks_data_set)
      });
      this.loading = false;
    },
    getFOREXList() {
      let listQuery = {
        "type": "FOREX" ,
      }
      this.loading = true;
      fetchMcxNseList(listQuery).then((response) => {
        this.forex_stocks_data_set = response.data.data;
      });
      this.loading = false;
    },


    handleCloseDialog() {
      this.formData.exchange = "NSE"
      this.dropdown_data_set = this.getTableList(this.nse_stocks_data_set);
      this.$emit("childClose");
    },

    scriptItemDetailsFun(temptStockData) {
      console.log(temptStockData);
      // let temptStockData = _.find(
      //   this.dropdown_data_set,
      //   (item) => {
      //     if (item.TradeSymbol == id) {
      //       return item;
      //     }
      //   }
      // );
      this.itemData = temptStockData;
      this.formData.stock_id = temptStockData.TokenNumber;
      this.formData.name = temptStockData.TradeSymbol;
      this.formData.tradeSymbol = temptStockData.TradeSymbol;
      this.formData.low = temptStockData.LowPriceRange;
      this.formData.high = temptStockData.HighPriceRange;
      this.formData.identifier = temptStockData.Identifier;
      this.formData.product = temptStockData.Product;
      this.formData.expiry = temptStockData.Expiry;
      this.formData.quotationLot = temptStockData.QuotationLot;

      this.formData.strike_price = temptStockData.StrikePrice ? temptStockData.StrikePrice : 0;
      this.formData.option_type = temptStockData.OptionType ? temptStockData.OptionType : "";
      this.formData.price_quotation_unit = temptStockData.PriceQuotationUnit ? temptStockData.PriceQuotationUnit : "";

      this.formData.buyPrice = 0;
      this.formData.sellPrice = 0;
      this.sendChildFormData()
    },

    sendChildFormData() {
      this.loading = true;
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          console.log("formData", this.formData);

          if (this.dialogType === "create") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
            });
          }
          //this.$emit("getChildFormData", this.formData);
          this.handleStore(this.formData)
          //this.loading = false;
        }
      });
    },
    handleStore(data){
        this.loading = true;
        // this.dialogVisible = false;
        addStock(data).then((response) => {
          if(response.data.success === true){
            this.flashSuccess(response.data.message);
            //this.handleChildClose()
            if(this.formData.exchange == "NSE"){
              let index = this.nse_stocks_data_set.findIndex((obj => obj.Identifier == data.identifier));
              this.nse_stocks_data_set[index].has_stock = true;
            }else if(this.formData.exchange == "MCX"){
              let index = this.mcx_stocks_data_set.findIndex((obj => obj.Identifier == data.identifier));
              this.mcx_stocks_data_set[index].has_stock = true;
            }else if(this.formData.exchange == "OPT"){
              let index = this.opt_stocks_data_set.findIndex((obj => obj.Identifier == data.identifier));
              this.opt_stocks_data_set[index].has_stock = true;
            }else if(this.formData.exchange == "CRYPTO"){
              let index = this.crypto_stocks_data_set.findIndex((obj => obj.Identifier == data.identifier));
              this.crypto_stocks_data_set[index].has_stock = true;
            }else if(this.formData.exchange == "FOREX"){
              let index = this.forex_stocks_data_set.findIndex((obj => obj.Identifier == data.identifier));
              this.forex_stocks_data_set[index].has_stock = true;
            }
            //this.radioFunction()
            this.loading = false;
            
          }else{
            this.flashError(response.data.message);
            //this.handleChildClose();
            this.loading = false;
            //this.getList();
          }

        });
    },
  },
};
</script>
