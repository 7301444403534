<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
    <!-- {{formData}} -->
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Name"
            >
            <br>
              <b>{{ formData.name }}</b>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :error="formErrors.lot_size"
              label="Lot Size"
              prop="lot_size"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.lot_size" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


     
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="primary" @click="sendChildFormData()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
export default {
  name: "AddEditDialog",
  components: {
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
    dialogTitle() {
      return this.dialogType === "edit" ? "Edit Script" : "Edit Script";
    },
  },
  created() {
    /**
     * Including  Of Validation Rule
     */
    this.formRules = {
      title: requiredRule("title"),
    };
  },
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$emit("getChildFormData", this.formData);
          console.log(this.formData);
          this.loading = false;
        }
      });
    },
  },
};
</script>
